.form {
  margin: 5rem auto;
  width: 35%;
}

.form label {
  display: block;
  margin: 1.5rem 0;
}

.form input {
  width: 100%;
  height: 4rem;
  border: none;
  background-color: var(--color-grey-light-1);
  box-sizing: border-box;
  padding-left: 1.5rem;
  color: var(--color-blue-dark);
}

.form input:focus {
  outline: none;
  border-bottom: 1px solid var(--color-blue-dark);
}

.form textarea {
  width: 100%;
  height: 20rem;
}

.form textarea:focus {
  outline-color: var(--color-blue-dark);
}

input[type='submit'] {
  border: none;
  background-color: var(--color-grey-light-2);
  transition: all 0.2s;
  color: var(--color-blue-dark);
  cursor: pointer;
  width: 40%;
}

input[type='submit']:focus {
  border: none;
}

@media (max-width: 78.13em) {
  .form {
    width: 50%;
  }
}

@media (max-width: 48.13em) {
  .form {
    width: 70%;
  }
}

@media (max-width: 26.25em) {
  /* 420px =  26.25em */
  .form {
    width: 100%;
  }
}
