@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --color-blue-dark: #101d2c;
  --color-blue-light: #0d263f;

  --color-white: #fff;

  --color-brown-light-1: #c69963;
  --color-brown-light-2: #b28451;

  --color-brown-dark-1: #6d5d4b;
  --color-brown-dark-2: #54483a;

  --color-grey-light-1: #f9f7f6;
  --color-grey-light-2: #aaa;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  /* 100% = 16px   ,62.5% = 10px hence , 1rem = 10px */
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}

body {
  font-size: 1.6rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  box-sizing: border-box;
  font-weight: 400;
  color: var(--color-grey-light-2);
}

@media (max-width: 78.13em) {
  html {
    /* 75em = 1250px */
    font-size: 56.25%; /* 1rem = 9px */
  }
}

@media (max-width: 56.25em) {
  html {
    /* 56.25em = 900px */
    font-size: 50%; /* 1rem = 8px */
  }
}

@media (max-width: 40.63em) {
  /* 40.63em = 650px */
  html {
    font-size: 43.75%;
  }
}
